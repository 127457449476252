@use '@norges-domstoler/dds-design-tokens/dist/scss/ddsTokens' as ddsTokens;
@use '../styles/css_constant' as cssConstants;

$max-width: 1400px;

.mosey-layout {
    .block-max-width {
        max-width: cssConstants.$block_max_width;
    }

    .footer-max-width {
        max-width: $max-width;
        margin: auto;
    }
}
